.myprogress {
  margin: 50px 0 50px 0;
  counter-reset: step;
}
.myprogress li {
  list-style-type: none;
  width: 33.33%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.myprogress li:before {
  width: 15px;
  height: 15px;
  content: '';
  line-height: 30px;
  border: 2px solid #7d7d7d;
  background-color: #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  transition: all .8s;
}
.myprogress li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 7px;
  left: -50%;
  z-index: -1;
  transition: all .8s;
}
.myprogress li:first-child:after {
  content: none;
}
.myprogress li.active:before {
  border-color: #55b776;
  background-color: #55b776;
  transition: all .8s;
}
.myprogress li.active:after {
  background-color: #55b776;
  transition: all .8s;
}
